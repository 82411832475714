import calendly from '../images/logos/calendly.png';
import facebook from '../images/logos/facebook.png';
import pipedrive from '../images/logos/pipedrive.png';
import whatsapp from '../images/logos/whatsapp.png';
import clearbit from '../images/logos/clearbit.png';
import gumroad from '../images/logos/gumroad.png';
import paddle from '../images/logos/paddle.png';
import openai from '../images/logos/openai.png';
import reveniu from '../images/logos/reveniu.png';
import stripe from '../images/logos/stripe.png';

import fullcontact from '../images/logos/fullcontact.png';
import slack from '../images/logos/slack.png';
import zoom from '../images/logos/zoom.png';
import dailytics from '../images/logos/dailytics.png';
import helpscout from '../images/logos/helpscout.png';
import twilio from '../images/logos/twilio.png';
import dialogflow from '../images/logos/dialogflow.png';
import messenger from '../images/logos/messenger.png';
import twitter from '../images/logos/twitter.png';
import openAi from '../images/logos/open_ai.png';

export default {
  calendly,
  facebook,
  pipedrive,
  whatsapp,
  clearbit,
  fullcontact,
  slack,
  zoom,
  dailytics,
  helpscout,
  stripe,
  twilio,
  dialogflow,
  messenger,
  twitter,
  openAi,
  gumroad,
  paddle,
  openai,
  reveniu,
};
